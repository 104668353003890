export const GET_ALL_USER_STAFF = 'GET_ALL_USER_STAFF'
export const SMS_STAFF_LOGIN_DETAILS = 'SMS_STAFF_LOGIN_DETAILS'
export const GENERATE_STAFF_LOGIN_REPORT = 'GENERATE_STAFF_LOGIN_REPORT'
export const EMAIL_STAFF_LOGIN_DETAILS = 'EMAIL_STAFF_LOGIN_DETAILS'
export const GENERATE_STAFF_LIST_REPORT_PDF = 'GENERATE_STAFF_LIST_REPORT_PDF'
export const ADD_STAFF = 'ADD_STAFF'
export const GET_STAFF = 'GET_STAFF'
export const UPDATE_STAFF = 'UPDATE_STAFF'
export const GET_STAFF_QUALIFICATION = 'GET_STAFF_QUALIFICATION'
export const GET_STAFF_EXPERIENCE = 'GET_STAFF_EXPERIENCE'
export const GET_STAFF_CHILDREN = 'GET_STAFF_CHILDREN'
export const UPLOAD_STAFF_SIGNATURE = 'UPLOAD_STAFF_SIGNATURE'
export const UPLOAD_USER_DOCS = 'UPLOAD_USER_DOCS'
export const GET_UPLOADED_DOCS = 'GET_UPLOADED_DOCS'
