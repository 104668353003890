import {
  GET_SUBJECT,
  GET_SUBJECTS,
  GET_SUBJECTS_STUDENTS,
  UPDATE_SUBJECTS,
  UPDATE_SUBJECT_SEQUENCE,
  GET_SUBJECT_SEQUENCE,
  GENERATE_STUDENT_LIST,
  GENERATE_OVERALL_SUBJECT_REPORT,
  SET_SUBJECT_WEIGHTAGE,
  CREATE_SUBJECT,
  STUDENT_SUBJECT_REPORT,
  LOCK_SELECTED_OUTLINE,
  UNLOCK_SELECTED_OUTLINE,
  GET_PREVIOUS_SUBJECTS,
  GET_SUBJECT_EXAMS,
  ASSIGN_SUBJECT_TEACHERS,
  GET_SUBSECTIONS,
  GET_SUBSECTION,
  CREATE_SUBSECTIONS,
  UPDATE_SUBSECTION,
  DELETE_MANY_SUBSECTION,
  DELETE_SUBSECTION,
  GET_EXCLUDE_SUBSECTIONS,
  UPDATE_EXCLUDE_SUBSECTION,
  COPY_SUBSECTION,
  GET_SYLLABUS_SUBJECTS,
} from 'setup/redux/Actions/Subject/ActionTypes/ApiActionTypes'
import {takeEvery} from 'redux-saga/effects'
import fetchData from '../../SagaHelper'

function* dataSaga() {
  yield takeEvery(GET_SUBJECTS, fetchData)
  yield takeEvery(GET_SUBJECT, fetchData)
  yield takeEvery(GET_SUBJECTS_STUDENTS, fetchData)
  yield takeEvery(CREATE_SUBJECT, fetchData)
  yield takeEvery(UPDATE_SUBJECTS, fetchData)
  yield takeEvery(UPDATE_SUBJECT_SEQUENCE, fetchData)
  yield takeEvery(GET_SUBJECT_SEQUENCE, fetchData)
  yield takeEvery(GENERATE_STUDENT_LIST, fetchData)
  yield takeEvery(GENERATE_OVERALL_SUBJECT_REPORT, fetchData)
  yield takeEvery(SET_SUBJECT_WEIGHTAGE, fetchData)
  yield takeEvery(STUDENT_SUBJECT_REPORT, fetchData)
  yield takeEvery(LOCK_SELECTED_OUTLINE, fetchData)
  yield takeEvery(UNLOCK_SELECTED_OUTLINE, fetchData)
  yield takeEvery(GET_PREVIOUS_SUBJECTS, fetchData)
  yield takeEvery(GET_SUBJECT_EXAMS, fetchData)
  yield takeEvery(ASSIGN_SUBJECT_TEACHERS, fetchData)
  yield takeEvery(GET_SUBSECTIONS, fetchData)
  yield takeEvery(CREATE_SUBSECTIONS, fetchData)
  yield takeEvery(UPDATE_SUBSECTION, fetchData)
  yield takeEvery(GET_SUBSECTION, fetchData)
  yield takeEvery(DELETE_MANY_SUBSECTION, fetchData)
  yield takeEvery(DELETE_SUBSECTION, fetchData)
  yield takeEvery(GET_EXCLUDE_SUBSECTIONS, fetchData)
  yield takeEvery(UPDATE_EXCLUDE_SUBSECTION, fetchData)
  yield takeEvery(COPY_SUBSECTION, fetchData)
  yield takeEvery(GET_SYLLABUS_SUBJECTS, fetchData)
}

export default dataSaga
