import {takeEvery} from 'redux-saga/effects'

import fetchData from '../../SagaHelper'
import {GET_EXAM_TYPES,VIEW_SELECTED_EXAM_TYPE,UPDATE_EXAM_TYPE,DELETE_MANY_EXAM_TYPES,DELETE_SINGLE_EXAM_TYPE,ADD_EXAM_TYPE} from 'setup/redux/Actions/ExamType/ActionTypes/ApiActionTypes'

function* dataSaga() {
  yield takeEvery(GET_EXAM_TYPES, fetchData)
  yield takeEvery(ADD_EXAM_TYPE, fetchData)
  yield takeEvery(DELETE_SINGLE_EXAM_TYPE, fetchData)
  yield takeEvery(DELETE_MANY_EXAM_TYPES, fetchData)
  yield takeEvery(UPDATE_EXAM_TYPE, fetchData)
  yield takeEvery(VIEW_SELECTED_EXAM_TYPE, fetchData)
}

export default dataSaga
