import {all} from 'redux-saga/effects'

import accountTypeSagas from './School/account_type/accountTypeSaga'
import admissionFeeVoucherDateSagas from './School/admissionFeeVoucherDate/admissionFeeVoucherDate'
import admissionStageSagas from './admissionStage/admissionStageSagas'
import attendanceSagas from './attendance/attendanceSagas'
import attendanceRemarksSagas from './attendanceRemarks/attendanceRemarks.sagas'
import attendanceScheduleSaga from './attendance/UserCustomAttendanceScheduleSagas'
import certificateSagas from './School/certificate/certificateSagas'
import classSagas from './School/class/classSagas'
import dashboardSagas from './dashboard/dashboardSagas'
import digitalRequestSagas from './School/digital_request/digitalRequestSagas'
import documentSagas from './document/documentSagas'
import feeSagas from './fee/feeSagas'
import forgotPasswordSaga from './login/forgotPasswordSaga'
import generalSagas from './general/generalSagas'
import goodsReceiptSagas from './School/goods_receipt/goodsReceiptSagas'
import gradeSaga from './School/grade/gradeSagas'
import homeworkSagas from './homework/homeworkSagas'
import invoiceSagas from './School/invoice/invoiceSagas'
import itemCategorySagas from './School/itemCategory/itemCategorySagas'
import labelSagas from './School/label/labelSagas'
import lecturesSagas from './lectures/lecturesSagas'
import librarySagas from './School/library/librarySagas'
import loginSaga from './login/loginSaga'
import levelSaga from './School/level/levelSagas'
import moduleNameSagas from './School/module_names/moduleNameSagas'
import notificationsSagas from './notifications/notificationsSagas'
import purchaseOrderSagas from './School/purchase_order/purchasOrderSagas'
import resourceSagas from './School/resource/resourceSagas'
import requestSagas from './School/request/requestSagas'
import schoolLectureSagas from './School/lecture/lectureSagas'
import schoolScheduleSagas from './School/schedule/scheduleSagas'
import schoolUserMStudentSagas from './School/student/studentSagas'
import schoolUserMParentSagas from './School/parent/parentSagas'
import schoolUserMTeacherSagas from './School/teacher/teacherSagas'
import schoolUserSagas from './School/user/user'
import sectionSaga from './School/section/sectionSagas'
import storeItemSagas from './School/storeItem/storeItemSagas'
import siteSagas from './site/siteSagas'
import staffRoleSagas from './School/staff_role/staff_role'
import staffSagas from './School/staff/staffSagas'
import subjectSagas from './School/subject/subjectSagas'
import tagSagas from './School/tags/tagSagas'
import tagsCategorySagas from './School/tagsCategory/tagsCategorySagas'
import threadSagas from './thread/threadSagas'
import transactionSagas from './School/transaction/transaction'
import userSagas from './user/userSagas'
import vendorSagas from './School/vendor/vendorSagas'
import logSagas from './School/logs/LogSagas'
import SmsLogSagas from './School/smsLog/smsLogSagas'
import NotificationLogSagas from './School/notificationLog/notifLogSagas'
import NewsSagas from './School/news/newsSagas'
import WhatsappLogSagas from './School/whatsappLog/whatsappLogSagas'
import BiometricCsvLogSagas from './School/biometricCsvLog/biometricCsvLogSagas'
import BiometricDeviceLogSagas from './School/biometricDeviceLog/biometricDeviceLogSagas'
import BiometricLogSagas from './School/biometricLog/biometricLogSagas'
import BranchSagas from './branch/branchSagas'
import PermissionSagas from './permission/permissionSagas'
import roleModulePermissionsSagas from './roleModulePermissions/roleModulePermissionsSagas'
import customNamesSagas from './School/customNames/customNamesSagas'
import bulkUploadCsv from './bulkUpload/bulkUploadCsvSagas'
import activitySagas from './activity/activitySagas'
import examSagas from './School/exam/examSagas'
import examTypeSagas from './School/examType/examTypeSagas'
import termSagas from './School/term/termSagas'

function* rootSaga() {
  yield all([
    accountTypeSagas(),
    admissionStageSagas(),
    admissionFeeVoucherDateSagas(),
    attendanceSagas(),
    attendanceRemarksSagas(),
    attendanceScheduleSaga(),
    classSagas(),
    certificateSagas(),
    dashboardSagas(),
    digitalRequestSagas(),
    documentSagas(),
    feeSagas(),
    bulkUploadCsv(),
    forgotPasswordSaga(),
    generalSagas(),
    goodsReceiptSagas(),
    gradeSaga(),
    homeworkSagas(),
    invoiceSagas(),
    itemCategorySagas(),
    labelSagas(),
    lecturesSagas(),
    librarySagas(),
    loginSaga(),
    levelSaga(),
    moduleNameSagas(),
    notificationsSagas(),
    purchaseOrderSagas(),
    PermissionSagas(),
    roleModulePermissionsSagas(),
    resourceSagas(),
    requestSagas(),
    schoolLectureSagas(),
    schoolScheduleSagas(),
    schoolUserSagas(),
    schoolUserMStudentSagas(),
    schoolUserMParentSagas(),
    schoolUserMTeacherSagas(),
    sectionSaga(),
    storeItemSagas(),
    siteSagas(),
    staffSagas(),
    staffRoleSagas(),
    subjectSagas(),
    tagSagas(),
    tagsCategorySagas(),
    threadSagas(),
    transactionSagas(),
    userSagas(),
    vendorSagas(),
    logSagas(),
    SmsLogSagas(),
    NotificationLogSagas(),
    NewsSagas(),
    WhatsappLogSagas(),
    BiometricCsvLogSagas(),
    BiometricDeviceLogSagas(),
    BiometricLogSagas(),
    BranchSagas(),
    customNamesSagas(),
    activitySagas(),
    examSagas(),
    examTypeSagas(),
    termSagas(),
  ])
}

export default rootSaga
