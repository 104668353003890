import fetchData from '../../SagaHelper'
import {takeEvery} from 'redux-saga/effects'
import {
  GET_ALL_USER_STAFF,
  SMS_STAFF_LOGIN_DETAILS,
  GENERATE_STAFF_LOGIN_REPORT,
  EMAIL_STAFF_LOGIN_DETAILS,
  GENERATE_STAFF_LIST_REPORT_PDF,
  ADD_STAFF,
  GET_STAFF,
  GET_STAFF_EXPERIENCE,
  GET_STAFF_QUALIFICATION,
  GET_STAFF_CHILDREN,
  UPDATE_STAFF,
  UPLOAD_STAFF_SIGNATURE,
  UPLOAD_USER_DOCS,
  GET_UPLOADED_DOCS,
} from 'setup/redux/Actions/Staff/ActionTypes/ApiActionTypes'

function* dataSaga() {
  yield takeEvery(GET_ALL_USER_STAFF, fetchData)
  yield takeEvery(ADD_STAFF, fetchData)
  yield takeEvery(GET_STAFF, fetchData)
  yield takeEvery(UPDATE_STAFF, fetchData)
  yield takeEvery(GET_STAFF_QUALIFICATION, fetchData)
  yield takeEvery(GET_STAFF_EXPERIENCE, fetchData)
  yield takeEvery(GET_STAFF_CHILDREN, fetchData)
  yield takeEvery(UPLOAD_STAFF_SIGNATURE, fetchData)
  yield takeEvery(UPLOAD_USER_DOCS, fetchData)
  yield takeEvery(GET_UPLOADED_DOCS, fetchData)
  yield takeEvery(EMAIL_STAFF_LOGIN_DETAILS, fetchData)
  yield takeEvery(GENERATE_STAFF_LOGIN_REPORT, fetchData)
  yield takeEvery(SMS_STAFF_LOGIN_DETAILS, fetchData)
  yield takeEvery(GENERATE_STAFF_LIST_REPORT_PDF, fetchData)
}

export default dataSaga
