export const GET_HOMEWORKS = 'GET_HOMEWORKS'
export const GET_HOMEWORK_DETAIL = 'GET_HOMEWORK_DETAIL'
export const GET_HOMEWORKS_MESSAGES = 'GET_HOMEWORK_MESSAGES'
export const SEND_HOMEWORK_MESSAGE = 'SEND_HOMEWORK_MESSAGE'
export const GET_SIGNED_UPLOAD_URL = 'GET_SIGNED_UPLOAD_URL'
export const CONFIRM_FILE_UPLOAD = 'CONFIRM_FILE_UPLOAD'
export const DELETE_HOMEWORK_FILE = 'DELETE_HOMEWORK_FILE'
export const SUBMIT_HOMEWORK = 'SUBMIT_HOMEWORK'
export const GET_ALL_HOMEWORK = 'GET_ALL_HOMEWORK'
export const GET_HOMEWORK = 'GET_HOMEWORK'
export const ADD_HOMEWORK = 'ADD_HOMEWORK'
export const GET_HOMEWORK_RESOURCES = 'GET_HOMEWORK_RESOURCES'
export const ADD_HOMEWORK_RESOURCE = 'ADD_HOMEWORK_RESOURCE'
export const DELETE_HOMEWORK = 'DELETE_HOMEWORK'
export const DELETE_HOMEWORK_RESOURCE = 'DELETE_HOMEWORK_RESOURCE'
export const UPDATE_HOMEWORK = 'UPDATE_HOMEWORK'
export const DELETE_MANY_HOMEWORK = 'DELETE_MANY_HOMEWORK'
export const ADD_MANY_HOMEWORK = 'ADD_MANY_HOMEWORK'
export const GET_HOMEWORK_RESULTS = 'GET_HOMEWORK_RESULTS'
export const GET_HOMEWORK_REPORTS = 'GET_HOMEWORK_REPORTS'
export const UPDATE_HOMEWORK_RESULT = 'UPDATE_HOMEWORK_RESULT'
export const VIEW_HOMEWORK_SUBMISSION = 'VIEW_HOMEWORK_SUBMISSION'
export const GET_HOMEWORK_FEEDBACK = 'GET_HOMEWORK_FEEDBACK'
export const ADD_HOMEWORK_FEEDBACK = 'ADD_HOMEWORK_FEEDBACK'
export const DELETE_HOMEWORK_FEEDBACK = 'DELETE_HOMEWORK_FEEDBACK'
export const ASSIGN_MARKS_TO_ALL_HOMEWORKS = 'ASSIGN_MARKS_TO_ALL_HOMEWORKS'
export const ASSIGN_TOP_HOMEWORKS = 'ASSIGN_TOP_HOMEWORKS'
export const GRADEWISE_HOMEWORK_LIMIT = 'GRADEWISE_HOMEWORK_LIMIT'
export const SEND_HOMEWORK_NOTIFICATION = 'SEND_HOMEWORK_NOTIFICATION'
export const SEND_HOMEWORK_SMS = 'SEND_HOMEWORK_SMS'
