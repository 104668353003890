import {RouteObject} from 'react-router-dom'
import {IntlShape} from 'react-intl'
import {MARITAL_STATUS} from 'common/constants'

export const USER_TYPES = {
  ADMIN: 'ADMIN',
  STUDENT: 'STUDENT',
  TEACHER: 'TEACHER',
  PARENT: 'PARENT',
  DEFAULT: 'DEFAULT',
  STAFF: 'STAFF',
}

export const ADMINISTRATOR = 1

export const USER_TYPES_NUMERIC = {
  ADMIN: 0,
  STUDENT: 1,
  TEACHER: 2,
  PARENT: 3,
  DEFAULT: 4,
  STAFF: 5,
}

export const USER_STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
  JUNK: 2,
}

export const USER_INACTIVE_REASON = {
  INACTIVE: 0,
  NEW_ADMISSION: 1,
  ALUMNI: 2,
  OTHER: 3,
}

export const PARENT_TYPES = {
  FATHER: 1,
  MOTHER: 2,
  GUARDIAN: 3,
}

export const INVOICE_TYPES = {
  BOTH: 'both',
  ISSUED: 'issued',
  SOLD: 'sold',
}

export const getCountryTypes = (intl: IntlShape) => [
  intl.formatMessage({id: 'COUNTRY.AFGHANISTAN'}),
  intl.formatMessage({id: 'COUNTRY.ALBANIA'}),
  intl.formatMessage({id: 'COUNTRY.ALGERIA'}),
  intl.formatMessage({id: 'COUNTRY.ANDORRA'}),
  intl.formatMessage({id: 'COUNTRY.ANGOLA'}),
  intl.formatMessage({id: 'COUNTRY.ANTIGUA_AND_BARBUDA'}),
  intl.formatMessage({id: 'COUNTRY.ARGENTINA'}),
  intl.formatMessage({id: 'COUNTRY.ARMENIA'}),
  intl.formatMessage({id: 'COUNTRY.AUSTRALIA'}),
  intl.formatMessage({id: 'COUNTRY.AUSTRIA'}),
  intl.formatMessage({id: 'COUNTRY.AZERBAIJAN'}),
  intl.formatMessage({id: 'COUNTRY.BAHAMAS'}),
  intl.formatMessage({id: 'COUNTRY.BAHRAIN'}),
  intl.formatMessage({id: 'COUNTRY.BANGLADESH'}),
  intl.formatMessage({id: 'COUNTRY.BARBADOS'}),
  intl.formatMessage({id: 'COUNTRY.BELARUS'}),
  intl.formatMessage({id: 'COUNTRY.BELGIUM'}),
  intl.formatMessage({id: 'COUNTRY.BELIZE'}),
  intl.formatMessage({id: 'COUNTRY.BENIN'}),
  intl.formatMessage({id: 'COUNTRY.BHUTAN'}),
  intl.formatMessage({id: 'COUNTRY.BOLIVIA'}),
  intl.formatMessage({id: 'COUNTRY.BOSNIA_AND_HERZEGOVINA'}),
  intl.formatMessage({id: 'COUNTRY.BOTSWANA'}),
  intl.formatMessage({id: 'COUNTRY.BRAZIL'}),
  intl.formatMessage({id: 'COUNTRY.BRUNEI'}),
  intl.formatMessage({id: 'COUNTRY.BULGARIA'}),
  intl.formatMessage({id: 'COUNTRY.BURKINA_FASO'}),
  intl.formatMessage({id: 'COUNTRY.BURUNDI'}),
  intl.formatMessage({id: 'COUNTRY.CABO_VERDE'}),
  intl.formatMessage({id: 'COUNTRY.CAMBODIA'}),
  intl.formatMessage({id: 'COUNTRY.CAMEROON'}),
  intl.formatMessage({id: 'COUNTRY.CANADA'}),
  intl.formatMessage({id: 'COUNTRY.CENTRAL_AFRICAN_REPUBLIC'}),
  intl.formatMessage({id: 'COUNTRY.CHAD'}),
  intl.formatMessage({id: 'COUNTRY.CHILE'}),
  intl.formatMessage({id: 'COUNTRY.CHINA'}),
  intl.formatMessage({id: 'COUNTRY.COLOMBIA'}),
  intl.formatMessage({id: 'COUNTRY.COMOROS'}),
  intl.formatMessage({id: 'COUNTRY.CONGO'}),
  intl.formatMessage({id: 'COUNTRY.COSTA_RICA'}),
  intl.formatMessage({id: 'COUNTRY.CROATIA'}),
  intl.formatMessage({id: 'COUNTRY.CUBA'}),
  intl.formatMessage({id: 'COUNTRY.CYPRUS'}),
  intl.formatMessage({id: 'COUNTRY.CZECH_REPUBLIC'}),
  intl.formatMessage({id: 'COUNTRY.DENMARK'}),
  intl.formatMessage({id: 'COUNTRY.DJIBOUTI'}),
  intl.formatMessage({id: 'COUNTRY.DOMINICA'}),
  intl.formatMessage({id: 'COUNTRY.DOMINICAN_REPUBLIC'}),
  intl.formatMessage({id: 'COUNTRY.EAST_TIMOR'}),
  intl.formatMessage({id: 'COUNTRY.ECUADOR'}),
  intl.formatMessage({id: 'COUNTRY.EGYPT'}),
  intl.formatMessage({id: 'COUNTRY.EL_SALVADOR'}),
  intl.formatMessage({id: 'COUNTRY.EQUATORIAL_GUINEA'}),
  intl.formatMessage({id: 'COUNTRY.ERITREA'}),
  intl.formatMessage({id: 'COUNTRY.ESTONIA'}),
  intl.formatMessage({id: 'COUNTRY.ESWATINI'}),
  intl.formatMessage({id: 'COUNTRY.ETHIOPIA'}),
  intl.formatMessage({id: 'COUNTRY.FIJI'}),
  intl.formatMessage({id: 'COUNTRY.FINLAND'}),
  intl.formatMessage({id: 'COUNTRY.FRANCE'}),
  intl.formatMessage({id: 'COUNTRY.GABON'}),
  intl.formatMessage({id: 'COUNTRY.GAMBIA'}),
  intl.formatMessage({id: 'COUNTRY.GEORGIA'}),
  intl.formatMessage({id: 'COUNTRY.GERMANY'}),
  intl.formatMessage({id: 'COUNTRY.GHANA'}),
  intl.formatMessage({id: 'COUNTRY.GREECE'}),
  intl.formatMessage({id: 'COUNTRY.GRENADA'}),
  intl.formatMessage({id: 'COUNTRY.GUATEMALA'}),
  intl.formatMessage({id: 'COUNTRY.GUINEA'}),
  intl.formatMessage({id: 'COUNTRY.GUINEA_BISSAU'}),
  intl.formatMessage({id: 'COUNTRY.GUYANA'}),
  intl.formatMessage({id: 'COUNTRY.HAITI'}),
  intl.formatMessage({id: 'COUNTRY.HONDURAS'}),
  intl.formatMessage({id: 'COUNTRY.HUNGARY'}),
  intl.formatMessage({id: 'COUNTRY.ICELAND'}),
  intl.formatMessage({id: 'COUNTRY.INDIA'}),
  intl.formatMessage({id: 'COUNTRY.INDONESIA'}),
  intl.formatMessage({id: 'COUNTRY.IRAN'}),
  intl.formatMessage({id: 'COUNTRY.IRAQ'}),
  intl.formatMessage({id: 'COUNTRY.IRELAND'}),
  intl.formatMessage({id: 'COUNTRY.ISRAEL'}),
  intl.formatMessage({id: 'COUNTRY.ITALY'}),
  intl.formatMessage({id: 'COUNTRY.IVORY_COAST'}),
  intl.formatMessage({id: 'COUNTRY.JAMAICA'}),
  intl.formatMessage({id: 'COUNTRY.JAPAN'}),
  intl.formatMessage({id: 'COUNTRY.JORDAN'}),
  intl.formatMessage({id: 'COUNTRY.KAZAKHSTAN'}),
  intl.formatMessage({id: 'COUNTRY.KENYA'}),
  intl.formatMessage({id: 'COUNTRY.KIRIBATI'}),
  intl.formatMessage({id: 'COUNTRY.KOREA_NORTH'}),
  intl.formatMessage({id: 'COUNTRY.KOREA_SOUTH'}),
  intl.formatMessage({id: 'COUNTRY.KOSOVO'}),
  intl.formatMessage({id: 'COUNTRY.KUWAIT'}),
  intl.formatMessage({id: 'COUNTRY.KYRGYZSTAN'}),
  intl.formatMessage({id: 'COUNTRY.LAOS'}),
  intl.formatMessage({id: 'COUNTRY.LATVIA'}),
  intl.formatMessage({id: 'COUNTRY.LEBANON'}),
  intl.formatMessage({id: 'COUNTRY.LESOTHO'}),
  intl.formatMessage({id: 'COUNTRY.LIBERIA'}),
  intl.formatMessage({id: 'COUNTRY.LIBYA'}),
  intl.formatMessage({id: 'COUNTRY.LIECHTENSTEIN'}),
  intl.formatMessage({id: 'COUNTRY.LITHUANIA'}),
  intl.formatMessage({id: 'COUNTRY.LUXEMBOURG'}),
  intl.formatMessage({id: 'COUNTRY.MADAGASCAR'}),
  intl.formatMessage({id: 'COUNTRY.MALAWI'}),
  intl.formatMessage({id: 'COUNTRY.MALAYSIA'}),
  intl.formatMessage({id: 'COUNTRY.MALDIVES'}),
  intl.formatMessage({id: 'COUNTRY.MALI'}),
  intl.formatMessage({id: 'COUNTRY.MALTA'}),
  intl.formatMessage({id: 'COUNTRY.MARSHALL_ISLANDS'}),
  intl.formatMessage({id: 'COUNTRY.MAURITANIA'}),
  intl.formatMessage({id: 'COUNTRY.MAURITIUS'}),
  intl.formatMessage({id: 'COUNTRY.MEXICO'}),
  intl.formatMessage({id: 'COUNTRY.MICRONESIA'}),
  intl.formatMessage({id: 'COUNTRY.MOLDOVA'}),
  intl.formatMessage({id: 'COUNTRY.MONACO'}),
  intl.formatMessage({id: 'COUNTRY.MONGOLIA'}),
  intl.formatMessage({id: 'COUNTRY.MONTENEGRO'}),
  intl.formatMessage({id: 'COUNTRY.MOROCCO'}),
  intl.formatMessage({id: 'COUNTRY.MOZAMBIQUE'}),
  intl.formatMessage({id: 'COUNTRY.MYANMAR'}),
  intl.formatMessage({id: 'COUNTRY.NAMIBIA'}),
  intl.formatMessage({id: 'COUNTRY.NAURU'}),
  intl.formatMessage({id: 'COUNTRY.NEPAL'}),
  intl.formatMessage({id: 'COUNTRY.NETHERLANDS'}),
  intl.formatMessage({id: 'COUNTRY.NEW_ZEALAND'}),
  intl.formatMessage({id: 'COUNTRY.NICARAGUA'}),
  intl.formatMessage({id: 'COUNTRY.NIGER'}),
  intl.formatMessage({id: 'COUNTRY.NIGERIA'}),
  intl.formatMessage({id: 'COUNTRY.NORTH_MACEDONIA'}),
  intl.formatMessage({id: 'COUNTRY.NORWAY'}),
  intl.formatMessage({id: 'COUNTRY.OMAN'}),
  intl.formatMessage({id: 'COUNTRY.PAKISTAN'}),
  intl.formatMessage({id: 'COUNTRY.PALAU'}),
  intl.formatMessage({id: 'COUNTRY.PALESTINE'}),
  intl.formatMessage({id: 'COUNTRY.PANAMA'}),
  intl.formatMessage({id: 'COUNTRY.PAPUA_NEW_GUINEA'}),
  intl.formatMessage({id: 'COUNTRY.PARAGUAY'}),
  intl.formatMessage({id: 'COUNTRY.PERU'}),
  intl.formatMessage({id: 'COUNTRY.PHILIPPINES'}),
  intl.formatMessage({id: 'COUNTRY.POLAND'}),
  intl.formatMessage({id: 'COUNTRY.PORTUGAL'}),
  intl.formatMessage({id: 'COUNTRY.QATAR'}),
  intl.formatMessage({id: 'COUNTRY.ROMANIA'}),
  intl.formatMessage({id: 'COUNTRY.RUSSIA'}),
  intl.formatMessage({id: 'COUNTRY.RWANDA'}),
  intl.formatMessage({id: 'COUNTRY.SAINT_KITTS_AND_NEVIS'}),
  intl.formatMessage({id: 'COUNTRY.SAINT_LUCIA'}),
  intl.formatMessage({id: 'COUNTRY.SAINT_VINCENT_AND_THE_GRENADINES'}),
  intl.formatMessage({id: 'COUNTRY.SAMOA'}),
  intl.formatMessage({id: 'COUNTRY.SAN_MARINO'}),
  intl.formatMessage({id: 'COUNTRY.SAO_TOME_AND_PRINCIPE'}),
  intl.formatMessage({id: 'COUNTRY.SAUDI_ARABIA'}),
  intl.formatMessage({id: 'COUNTRY.SENEGAL'}),
  intl.formatMessage({id: 'COUNTRY.SERBIA'}),
  intl.formatMessage({id: 'COUNTRY.SEYCHELLES'}),
  intl.formatMessage({id: 'COUNTRY.SIERRA_LEONE'}),
  intl.formatMessage({id: 'COUNTRY.SINGAPORE'}),
  intl.formatMessage({id: 'COUNTRY.SLOVAKIA'}),
  intl.formatMessage({id: 'COUNTRY.SLOVENIA'}),
  intl.formatMessage({id: 'COUNTRY.SOLOMON_ISLANDS'}),
  intl.formatMessage({id: 'COUNTRY.SOMALIA'}),
  intl.formatMessage({id: 'COUNTRY.SOUTH_AFRICA'}),
  intl.formatMessage({id: 'COUNTRY.SOUTH_SUDAN'}),
  intl.formatMessage({id: 'COUNTRY.SPAIN'}),
  intl.formatMessage({id: 'COUNTRY.SRI_LANKA'}),
  intl.formatMessage({id: 'COUNTRY.SUDAN'}),
  intl.formatMessage({id: 'COUNTRY.SURINAME'}),
  intl.formatMessage({id: 'COUNTRY.SWEDEN'}),
  intl.formatMessage({id: 'COUNTRY.SWITZERLAND'}),
  intl.formatMessage({id: 'COUNTRY.SYRIA'}),
  intl.formatMessage({id: 'COUNTRY.TAIWAN'}),
  intl.formatMessage({id: 'COUNTRY.TAJIKISTAN'}),
  intl.formatMessage({id: 'COUNTRY.TANZANIA'}),
  intl.formatMessage({id: 'COUNTRY.THAILAND'}),
  intl.formatMessage({id: 'COUNTRY.TOGO'}),
  intl.formatMessage({id: 'COUNTRY.TONGA'}),
  intl.formatMessage({id: 'COUNTRY.TRINIDAD_AND_TOBAGO'}),
  intl.formatMessage({id: 'COUNTRY.TUNISIA'}),
  intl.formatMessage({id: 'COUNTRY.TURKEY'}),
  intl.formatMessage({id: 'COUNTRY.TURKMENISTAN'}),
  intl.formatMessage({id: 'COUNTRY.TUVALU'}),
  intl.formatMessage({id: 'COUNTRY.UGANDA'}),
  intl.formatMessage({id: 'COUNTRY.UKRAINE'}),
  intl.formatMessage({id: 'COUNTRY.UNITED_ARAB_EMIRATES'}),
  intl.formatMessage({id: 'COUNTRY.UNITED_KINGDOM'}),
  intl.formatMessage({id: 'COUNTRY.UNITED_STATES'}),
  intl.formatMessage({id: 'COUNTRY.URUGUAY'}),
  intl.formatMessage({id: 'COUNTRY.UZBEKISTAN'}),
  intl.formatMessage({id: 'COUNTRY.VANUATU'}),
  intl.formatMessage({id: 'COUNTRY.VATICAN_CITY'}),
  intl.formatMessage({id: 'COUNTRY.VENEZUELA'}),
  intl.formatMessage({id: 'COUNTRY.VIETNAM'}),
  intl.formatMessage({id: 'COUNTRY.YEMEN'}),
  intl.formatMessage({id: 'COUNTRY.ZAMBIA'}),
  intl.formatMessage({id: 'COUNTRY.ZIMBABWE'}),
]

export const getSocialTypes = (intl: IntlShape) => [
  intl.formatMessage({id: 'SOCIAL.FACEBOOK'}),
  intl.formatMessage({id: 'SOCIAL.INSTAGRAM'}),
  intl.formatMessage({id: 'SOCIAL.TWITTER'}),
  intl.formatMessage({id: 'SOCIAL.TIKTOK'}),
  intl.formatMessage({id: 'SOCIAL.LINKEDIN'}),
]

export const getReligionTypes = (intl: IntlShape) => [
  intl.formatMessage({id: 'RELIGION.AFRICAN_TRADITIONAL_DIASPORIC'}),
  intl.formatMessage({id: 'RELIGION.AGNOSTIC'}),
  intl.formatMessage({id: 'RELIGION.ATHEISM'}),
  intl.formatMessage({id: 'RELIGION.BAHAI'}),
  intl.formatMessage({id: 'RELIGION.BUDDHISM'}),
  intl.formatMessage({id: 'RELIGION.CAO_DAI'}),
  intl.formatMessage({id: 'RELIGION.CHINESE_TRADITIONAL'}),
  intl.formatMessage({id: 'RELIGION.CHRISTIANITY'}),
  intl.formatMessage({id: 'RELIGION.HINDUISM'}),
  intl.formatMessage({id: 'RELIGION.ISLAM'}),
  intl.formatMessage({id: 'RELIGION.JAINISM'}),
  intl.formatMessage({id: 'RELIGION.JUCHE'}),
  intl.formatMessage({id: 'RELIGION.JUDAISM'}),
  intl.formatMessage({id: 'RELIGION.NEO_PAGANISM'}),
  intl.formatMessage({id: 'RELIGION.NONRELIGIOUS'}),
  intl.formatMessage({id: 'RELIGION.RASTAFARIANISM'}),
  intl.formatMessage({id: 'RELIGION.SECULAR'}),
  intl.formatMessage({id: 'RELIGION.SHINTO'}),
  intl.formatMessage({id: 'RELIGION.SIKHISM'}),
  intl.formatMessage({id: 'RELIGION.SPIRITISM'}),
  intl.formatMessage({id: 'RELIGION.TENRIKYO'}),
  intl.formatMessage({id: 'RELIGION.UNITARIAN_UNIVERSALISM'}),
  intl.formatMessage({id: 'RELIGION.ZOROASTRIANISM'}),
  intl.formatMessage({id: 'RELIGION.PRIMAL_INDIGENOUS'}),
  intl.formatMessage({id: 'RELIGION.OTHER'}),
]

export const getGenderTypes = (intl: IntlShape) => [
  {label: intl.formatMessage({id: 'GENDER.MALE'}), value: 0},
  {label: intl.formatMessage({id: 'GENDER.FEMALE'}), value: 1},
]

export const getNationalityTypes = (intl: IntlShape) => [
  intl.formatMessage({id: 'NATIONALITY.AFGHAN'}),
  intl.formatMessage({id: 'NATIONALITY.ALBANIAN'}),
  intl.formatMessage({id: 'NATIONALITY.ALGARIAN'}),
  intl.formatMessage({id: 'NATIONALITY.AMERICAN'}),
  intl.formatMessage({id: 'NATIONALITY.ANDORRAN'}),
  intl.formatMessage({id: 'NATIONALITY.ANGOLAN'}),
  intl.formatMessage({id: 'NATIONALITY.ANTIGUAN'}),
  intl.formatMessage({id: 'NATIONALITY.ARGENTINE'}),
  intl.formatMessage({id: 'NATIONALITY.ARMENIAN'}),
  intl.formatMessage({id: 'NATIONALITY.AUSTRALIAN'}),
  intl.formatMessage({id: 'NATIONALITY.AUSTRIAN'}),
  intl.formatMessage({id: 'NATIONALITY.AZERBAIJANI'}),
  intl.formatMessage({id: 'NATIONALITY.BAHAMIAN'}),
  intl.formatMessage({id: 'NATIONALITY.BAHRANI'}),
  intl.formatMessage({id: 'NATIONALITY.BANGLADESHI'}),
  intl.formatMessage({id: 'NATIONALITY.BARBADIAN'}),
  intl.formatMessage({id: 'NATIONALITY.BARBADIAN'}),
  intl.formatMessage({id: 'NATIONALITY.BOTSWANAN'}),
  intl.formatMessage({id: 'NATIONALITY.BELARUSIAN'}),
  intl.formatMessage({id: 'NATIONALITY.BELGIAN'}),
  intl.formatMessage({id: 'NATIONALITY.BELIZEAN'}),
  intl.formatMessage({id: 'NATIONALITY.BENINESE'}),
  intl.formatMessage({id: 'NATIONALITY.BHUTANESE'}),
  intl.formatMessage({id: 'NATIONALITY.BOLIVIAN'}),
  intl.formatMessage({id: 'NATIONALITY.BOSNIAN'}),
  intl.formatMessage({id: 'NATIONALITY.BRAZILIAN'}),
  intl.formatMessage({id: 'NATIONALITY.BRITISH'}),
  intl.formatMessage({id: 'NATIONALITY.BRUNEIAN'}),
  intl.formatMessage({id: 'NATIONALITY.BULGARIAN'}),
  intl.formatMessage({id: 'NATIONALITY.BURKINAB'}),
  intl.formatMessage({id: 'NATIONALITY.BURMESE'}),
  intl.formatMessage({id: 'NATIONALITY.BURUNDIAN'}),
  intl.formatMessage({id: 'NATIONALITY.CAMBODIAN'}),
  intl.formatMessage({id: 'NATIONALITY.CAMEROONIAN'}),
  intl.formatMessage({id: 'NATIONALITY.CANADIAN'}),
  intl.formatMessage({id: 'NATIONALITY.CAPE_VERDEAN'}),
  intl.formatMessage({id: 'NATIONALITY.CENTRAL_AFRICAN'}),
  intl.formatMessage({id: 'NATIONALITY.CHADIAN'}),
  intl.formatMessage({id: 'NATIONALITY.CHILEAN'}),
  intl.formatMessage({id: 'NATIONALITY.CHINESE'}),
  intl.formatMessage({id: 'NATIONALITY.COLOMBIAN'}),
  intl.formatMessage({id: 'NATIONALITY.COMORAN'}),
  intl.formatMessage({id: 'NATIONALITY.CONGOLESE'}),
  intl.formatMessage({id: 'NATIONALITY.COSTA_RICAN'}),
  intl.formatMessage({id: 'NATIONALITY.CROATIAN'}),
  intl.formatMessage({id: 'NATIONALITY.CUBAN'}),
  intl.formatMessage({id: 'NATIONALITY.CYPRIOT'}),
  intl.formatMessage({id: 'NATIONALITY.CZECH'}),
  intl.formatMessage({id: 'NATIONALITY.DANE'}),
  intl.formatMessage({id: 'NATIONALITY.DJIBOUTIAN'}),
  intl.formatMessage({id: 'NATIONALITY.DOMINICAN'}),
  intl.formatMessage({id: 'NATIONALITY.DUTCH'}),
  intl.formatMessage({id: 'NATIONALITY.ECUADOREAN'}),
  intl.formatMessage({id: 'NATIONALITY.EGYPTIAN'}),
  intl.formatMessage({id: 'NATIONALITY.EMIRATI'}),
  intl.formatMessage({id: 'NATIONALITY.EQUATORIAL_GUINEAN'}),
  intl.formatMessage({id: 'NATIONALITY.ERITREAN'}),
  intl.formatMessage({id: 'NATIONALITY.ESTONIAN'}),
  intl.formatMessage({id: 'NATIONALITY.ETHIOPIAN'}),
  intl.formatMessage({id: 'NATIONALITY.FIJIAN'}),
  intl.formatMessage({id: 'NATIONALITY.FILIPINO'}),
  intl.formatMessage({id: 'NATIONALITY.FINNISH'}),
  intl.formatMessage({id: 'NATIONALITY.FRENCH'}),
  intl.formatMessage({id: 'NATIONALITY.GABONESE'}),
  intl.formatMessage({id: 'NATIONALITY.GAMBIAN'}),
  intl.formatMessage({id: 'NATIONALITY.GEORGIAN'}),
  intl.formatMessage({id: 'NATIONALITY.GERMAN'}),
  intl.formatMessage({id: 'NATIONALITY.GHANAIAN'}),
  intl.formatMessage({id: 'NATIONALITY.GREEK'}),
  intl.formatMessage({id: 'NATIONALITY.GRENADIAN'}),
  intl.formatMessage({id: 'NATIONALITY.GUATEMALAN'}),
  intl.formatMessage({id: 'NATIONALITY.GUINEAN'}),
  intl.formatMessage({id: 'NATIONALITY.GUINEA_BISSAUAN'}),
  intl.formatMessage({id: 'NATIONALITY.GUYANESE'}),
  intl.formatMessage({id: 'NATIONALITY.HAITIAN'}),
  intl.formatMessage({id: 'NATIONALITY.HONDURAN'}),
  intl.formatMessage({id: 'NATIONALITY.HUNGARIAN'}),
  intl.formatMessage({id: 'NATIONALITY.ICELANDER'}),
  intl.formatMessage({id: 'NATIONALITY.INDIAN'}),
  intl.formatMessage({id: 'NATIONALITY.INDONESIAN'}),
  intl.formatMessage({id: 'NATIONALITY.IRANIAN'}),
  intl.formatMessage({id: 'NATIONALITY.IRAQI'}),
  intl.formatMessage({id: 'NATIONALITY.IRISH'}),
  intl.formatMessage({id: 'NATIONALITY.ISRAELI'}),
  intl.formatMessage({id: 'NATIONALITY.ITALIAN'}),
  intl.formatMessage({id: 'NATIONALITY.IVORIAN'}),
  intl.formatMessage({id: 'NATIONALITY.JAMAICAN'}),
  intl.formatMessage({id: 'NATIONALITY.JAPANESE'}),
  intl.formatMessage({id: 'NATIONALITY.JORDANIAN'}),
  intl.formatMessage({id: 'NATIONALITY.KAZAKH'}),
  intl.formatMessage({id: 'NATIONALITY.KENYAN'}),
  intl.formatMessage({id: 'NATIONALITY.KIRIBATI'}),
  intl.formatMessage({id: 'NATIONALITY.KOREAN'}),
  intl.formatMessage({id: 'NATIONALITY.KUWAITI'}),
  intl.formatMessage({id: 'NATIONALITY.KYRGYZ'}),
  intl.formatMessage({id: 'NATIONALITY.LAO'}),
  intl.formatMessage({id: 'NATIONALITY.LATVIAN'}),
  intl.formatMessage({id: 'NATIONALITY.LEBANESE'}),
  intl.formatMessage({id: 'NATIONALITY.LIBERIAN'}),
  intl.formatMessage({id: 'NATIONALITY.LIBYAN'}),
  intl.formatMessage({id: 'NATIONALITY.LIECHTENSTEINER'}),
  intl.formatMessage({id: 'NATIONALITY.LITHUANIAN'}),
  intl.formatMessage({id: 'NATIONALITY.LUXEMBOURGISH'}),
  intl.formatMessage({id: 'NATIONALITY.MADAGASY'}),
  intl.formatMessage({id: 'NATIONALITY.MALAWIAN'}),
  intl.formatMessage({id: 'NATIONALITY.MALAYSIAN'}),
  intl.formatMessage({id: 'NATIONALITY.MALDIVIAN'}),
  intl.formatMessage({id: 'NATIONALITY.MALIAN'}),
  intl.formatMessage({id: 'NATIONALITY.MALTESE'}),
  intl.formatMessage({id: 'NATIONALITY.MARSHALLESE'}),
  intl.formatMessage({id: 'NATIONALITY.MAURITANIAN'}),
  intl.formatMessage({id: 'NATIONALITY.MAURITIAN'}),
  intl.formatMessage({id: 'NATIONALITY.MEXICAN'}),
  intl.formatMessage({id: 'NATIONALITY.MICRONESIAN'}),
  intl.formatMessage({id: 'NATIONALITY.MOLDOVAN'}),
  intl.formatMessage({id: 'NATIONALITY.MONACAN'}),
  intl.formatMessage({id: 'NATIONALITY.MONGOLIAN'}),
  intl.formatMessage({id: 'NATIONALITY.MONTENEGRIN'}),
  intl.formatMessage({id: 'NATIONALITY.MOROCCAN'}),
  intl.formatMessage({id: 'NATIONALITY.MOZAMBICAN'}),
  intl.formatMessage({id: 'NATIONALITY.MYANMAR'}),
  intl.formatMessage({id: 'NATIONALITY.NAMIBIAN'}),
  intl.formatMessage({id: 'NATIONALITY.NAURUAN'}),
  intl.formatMessage({id: 'NATIONALITY.NEPAL'}),
  intl.formatMessage({id: 'NATIONALITY.NEW_ZEALANDER'}),
  intl.formatMessage({id: 'NATIONALITY.NICARAGUAN'}),
  intl.formatMessage({id: 'NATIONALITY.NIGERIAN'}),
  intl.formatMessage({id: 'NATIONALITY.NIGERIEN'}),
  intl.formatMessage({id: 'NATIONALITY.KOREAN'}),
  intl.formatMessage({id: 'NATIONALITY.NORWEGIAN'}),
  intl.formatMessage({id: 'NATIONALITY.OMANI'}),
  intl.formatMessage({id: 'NATIONALITY.PAKISTANI'}),
  intl.formatMessage({id: 'NATIONALITY.PALAUAN'}),
  intl.formatMessage({id: 'NATIONALITY.PALESTINIAN'}),
  intl.formatMessage({id: 'NATIONALITY.PANAMANIAN'}),
  intl.formatMessage({id: 'NATIONALITY.PAPUA_NEW_GUINEAN'}),
  intl.formatMessage({id: 'NATIONALITY.PARAGUAYAN'}),
  intl.formatMessage({id: 'NATIONALITY.PERUVIAN'}),
  intl.formatMessage({id: 'NATIONALITY.POLISH'}),
  intl.formatMessage({id: 'NATIONALITY.PORTUGUESE'}),
  intl.formatMessage({id: 'NATIONALITY.QATARI'}),
  intl.formatMessage({id: 'NATIONALITY.ROMANIAN'}),
  intl.formatMessage({id: 'NATIONALITY.RUSSIAN'}),
  intl.formatMessage({id: 'NATIONALITY.RWANDAN'}),
  intl.formatMessage({id: 'NATIONALITY.SALVADORAN'}),
  intl.formatMessage({id: 'NATIONALITY.SAMOAN'}),
  intl.formatMessage({id: 'NATIONALITY.SAN_MARINESE'}),
  intl.formatMessage({id: 'NATIONALITY.SAUDI'}),
  intl.formatMessage({id: 'NATIONALITY.SENEGALESE'}),
  intl.formatMessage({id: 'NATIONALITY.SERBIAN'}),
  intl.formatMessage({id: 'NATIONALITY.SEYCHELLOIS'}),
  intl.formatMessage({id: 'NATIONALITY.SIERRA_LEONEAN'}),
  intl.formatMessage({id: 'NATIONALITY.SINGAPOREAN'}),
  intl.formatMessage({id: 'NATIONALITY.SLOVAK'}),
  intl.formatMessage({id: 'NATIONALITY.SLOVENIAN'}),
  intl.formatMessage({id: 'NATIONALITY.SOLOMON_ISLANDER'}),
  intl.formatMessage({id: 'NATIONALITY.SOMALI'}),
  intl.formatMessage({id: 'NATIONALITY.SOUTH_AFRICAN'}),
  intl.formatMessage({id: 'NATIONALITY.SOUTH_SUDANESE'}),
  intl.formatMessage({id: 'NATIONALITY.SPANISH'}),
  intl.formatMessage({id: 'NATIONALITY.SRI_LANKAN'}),
  intl.formatMessage({id: 'NATIONALITY.SUDANESE'}),
  intl.formatMessage({id: 'NATIONALITY.SURINAMESE'}),
  intl.formatMessage({id: 'NATIONALITY.SWAZI'}),
  intl.formatMessage({id: 'NATIONALITY.SWEDISH'}),
  intl.formatMessage({id: 'NATIONALITY.SWISS'}),
  intl.formatMessage({id: 'NATIONALITY.SYRIAN'}),
  intl.formatMessage({id: 'NATIONALITY.TAIWANESE'}),
  intl.formatMessage({id: 'NATIONALITY.TAJIK'}),
  intl.formatMessage({id: 'NATIONALITY.TANZANIAN'}),
  intl.formatMessage({id: 'NATIONALITY.THAI'}),
  intl.formatMessage({id: 'NATIONALITY.TOGOLESE'}),
  intl.formatMessage({id: 'NATIONALITY.TONGAN'}),
  intl.formatMessage({id: 'NATIONALITY.TRINIDADIAN'}),
  intl.formatMessage({id: 'NATIONALITY.TUNISIAN'}),
  intl.formatMessage({id: 'NATIONALITY.TURKISH'}),
  intl.formatMessage({id: 'NATIONALITY.TUVALUAN'}),
  intl.formatMessage({id: 'NATIONALITY.UGANDAN'}),
  intl.formatMessage({id: 'NATIONALITY.UKRANIAN'}),
  intl.formatMessage({id: 'NATIONALITY.URUGUAYAN'}),
  intl.formatMessage({id: 'NATIONALITY.UZBEK'}),
  intl.formatMessage({id: 'NATIONALITY.VENEZUELAN'}),
  intl.formatMessage({id: 'NATIONALITY.VIETNAMESE'}),
  intl.formatMessage({id: 'NATIONALITY.YEMENI'}),
  intl.formatMessage({id: 'NATIONALITY.ZAMBIAN'}),
  intl.formatMessage({id: 'NATIONALITY.ZIMBABWEAN'}),
]

export const getBloodGroupTypes = (intl: IntlShape) => [
  intl.formatMessage({id: 'BLOOD_GROUP.A_POSITIVE'}),
  intl.formatMessage({id: 'BLOOD_GROUP.A_NEGATIVE'}),
  intl.formatMessage({id: 'BLOOD_GROUP.B_POSITIVE'}),
  intl.formatMessage({id: 'BLOOD_GROUP.B_NEGATIVE'}),
  intl.formatMessage({id: 'BLOOD_GROUP.O_POSITIVE'}),
  intl.formatMessage({id: 'BLOOD_GROUP.O_NEGATIVE'}),
  intl.formatMessage({id: 'BLOOD_GROUP.AB_POSITIVE'}),
  intl.formatMessage({id: 'BLOOD_GROUP.AB_NEGATIVE'}),
]
export const getCategoryTypes = (intl: IntlShape) => [
  intl.formatMessage({id: 'CATEGORY.STAFF_CHILD'}),
  intl.formatMessage({id: 'CATEGORY.EMBASSY'}),
  intl.formatMessage({id: 'CATEGORY.DEATH_CASE'}),
  intl.formatMessage({id: 'CATEGORY.OTHERS'}),
]
export const getMaritalTypes = (intl: IntlShape) => [
  {value: 0, label: intl.formatMessage({id: 'MARITAL.MARRIED'})},
  {value: 1, label: intl.formatMessage({id: 'MARITAL.SEPARATED'})},
  {value: 2, label: intl.formatMessage({id: 'MARITAL.DIVORCED'})},
  {value: 3, label: intl.formatMessage({id: 'MARITAL.WIDOW(ER)'})},
]

export const getTeacherMaritalTypes = (intl: IntlShape) => [
  {value: 0, label: intl.formatMessage({id: 'MARITAL.SINGLE'})},
  {value: 1, label: intl.formatMessage({id: 'MARITAL.MARRIED'})},
  {value: 2, label: intl.formatMessage({id: 'MARITAL.SEPARATED'})},
  {value: 3, label: intl.formatMessage({id: 'MARITAL.DIVORCED'})},
  {value: 4, label: intl.formatMessage({id: 'MARITAL.WIDOW(ER)'})},
]

export const getInactivationReason = (intl: IntlShape) => [
  {value: 0, label: intl.formatMessage({id: 'PARENT.INACTIVE_REASON_INACTIVE'})},
  {value: 1, label: intl.formatMessage({id: 'PARENT.INACTIVE_REASON_NEW_ADMISSION'})},
  {value: 2, label: intl.formatMessage({id: 'PARENT.INACTIVE_REASON_ALUMNI'})},
  {value: 3, label: intl.formatMessage({id: 'PARENT.INACTIVE_REASON_OTHERS'})},
]

export const getMaritalStatus = (status: number, intl: IntlShape) => {
  switch (status) {
    case MARITAL_STATUS.MARRIED:
      return intl.$t({id: 'MARITAL.MARRIED'})
    case MARITAL_STATUS.SEPARATED:
      return intl.$t({id: 'MARITAL.SEPARATED'})
    case MARITAL_STATUS.DIVORCED:
      return intl.$t({id: 'MARITAL.DIVORCED'})
    case MARITAL_STATUS.WIDOW:
      return intl.$t({id: 'MARITAL.WIDOW(ER)'})
    default:
      return intl.$t({id: 'CLASS.NOT_SET'})
  }
}

export const USER_BETA_TOGGLE_TENANTS = [
  // 'creatics',
  'demo',
  'democollege',
  // 'lgs',
  // 'maryammont',
  'myschool',
  // 'ivss',
  // 'pgss',
  // 'risc',
  // 'zicas',
  'lgsdev2',
  // 'lgs',
]

export const USER_MODULE_BETA_KEY = 'user-beta-toggle'

export const USER_SHOW_ROUTES: RouteObject[] = [
  {path: '/institute/inactive-users/*'},
  {path: '/institute/parent/*'},
  {path: '/institute/staffRole/*'},
  {path: '/institute/staff/*'},
  {path: '/institute/student/*'},
  {path: '/institute/teacher/*'},
  {path: '/institute/user/*'},
  {path: '/institute/userLabel/*'},
]
