import {
  REDUX_SELECT_LANGUAGE,
  REDUX_STATE_LOADING,
  REDUX_SAVE_SUBJECTS,
  REDUX_SET_HEADER_DROPDOWN,
  REDUX_GENERAL_SETTINGS,
  REDUX_SET_BRANCHES,
  REDUX_SET_CUSTOM_TITLES,
  REDUX_TENANT_SESSIONS,
  REDUX_GET_CLASSES,
  REDUX_GET_GRADES,

  
} from '../Actions/General/ActionTypes/ReduxActionTypes'

import {REDUX_GET_ATTENDANCE_SETTING} from '../Actions/Attendance/ActionTypes/ReduxAtionTypes'
import {REDUX_GET_USER_SUBJECTS} from '../Actions/Subject/ActionTypes/ReduxActionTypes'
import {REDUX_LEVELS} from '../Actions/Level/ActionTypes/ReduxActionTypes'
import {hasPermission, isAdmin} from 'common/HelperFunctions'

const initialState = {
  selectedLanguage: '',
  isFetching: {},
  subjects: [],
  headerDropdown: false,
  generalSettings: {},
  branches: [],
  userBranches: [],
  custom_titles: [],
  tenantSessions: {},
  classes: [],
  grades: [],
  attendance_setting: [],
  get_user_subjects: [],
  levels: [],
}

const subjectColors = [
  '#d50000',
  '#c51162',
  '#aa00ff',
  '#6200ea',
  '#304ffe',
  '#2962ff',
  '#0091ea',
  '#00b8d4',
  '#00bfa5',
  '#00c853',
  '#64dd17',
  '#aeea00',
  '#ffd600',
  '#ffab00',
  '#ff6d00',
  '#dd2c00',
  '#3e2723',
  '#212121',
  '#455a64',
]

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_SELECT_LANGUAGE:
      return {
        ...state,
        selectedLanguage: action.payload.data,
      }
    case REDUX_STATE_LOADING:
      const {data} = action.payload
      return {
        ...state,
        isFetching: {...state.isFetching, ...data},
      }
    case REDUX_SAVE_SUBJECTS:
      let {result} = action.payload
      result.map((subject, i) => {
        return (subject.color = subjectColors[i]
          ? subjectColors[i]
          : ('#' + ((Math.random() * 0xffffff) << 0).toString(16) + '000000').slice(0, 7))
      })
      return {
        ...state,
        subjects: result,
      }
    case REDUX_SET_HEADER_DROPDOWN:
      return {
        ...state,
        headerDropdown: action.payload.data,
      }
    case REDUX_GENERAL_SETTINGS:
      if (action?.payload?.result?.tabTitle) document.title = action.payload.result.tabTitle
      return {
        ...state,
        generalSettings: action.payload.result,
      }
    case REDUX_SET_BRANCHES:
      const branches = action.payload.result

      // * userBranches are set in the payload in fetchData saga helper as we can not access userReducer here which is need for filtering branches
      const userBranches = action.payload.userBranches

      return {
        ...state,
        branches,
        userBranches,
      }
    case REDUX_SET_CUSTOM_TITLES:
      return {
        ...state,
        custom_titles: action.payload.result,
      }
    case REDUX_TENANT_SESSIONS:
      return {
        ...state,
        tenantSessions: action.payload.result,
      }
    case REDUX_GET_CLASSES:
      return {
        ...state,
        classes: action.payload.result?.data || [],
      }

    case REDUX_GET_ATTENDANCE_SETTING:
      return {
        ...state,
        attendance_setting: action.payload.result?.data,
      }

    case REDUX_GET_USER_SUBJECTS:
      return {
        ...state,
        get_user_subjects: action.payload.result.data,
      }
    case REDUX_LEVELS:
      return {
        ...state,
        levels: action.payload.result.data,
      }
    case REDUX_GET_GRADES:
      return {
        ...state,
        grades: action.payload.result?.data,
      }
     
    default:
      return state
  }
}

export default reducer
